import React, { useEffect } from "react";
import "./src/large.scss";
import { useGlobalSize } from "../../hooks/useGlobalSize";
import Header from "../../components/header";
import Sections from "../../components/sections";
const Large = (e: any) => {
  const wh = {
    width: 1920,
    height: 1080,
  };
  const openUrl = () => {
    window.open("http://192.168.0.129:3000/");
  };
  const { scale } = useGlobalSize(wh);
  return (
    <div className="large">
      <Header />
      <Sections />
    </div>
  );
};
export default Large;
