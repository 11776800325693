import instance from "../utils/requset";
// 用户登录
export const weatherInfo = () => {
    return instance.request({
        url: "/intelligent/data/wall/weatherInfo",
        method: "GET"
    });
};

export const  getDeviceAllInfo=()=>{
    return instance.request({
        url: "/intelligent/data/wall/getDeviceAllInfo",
        method: "GET"
    });
}
export const  listHistoryData=(params:any)=>{
    return instance.request({
        url: "/intelligent/data/wall/listHistoryData",
        method: "GET",
        params
    });
}

export const  getUserGroupInfo=(params:any)=>{
    return instance.request({
        url: "/intelligent/data/wall/getUserGroupInfo",
        method: "GET",
        params
    });
}

export const soilData=()=>{
    return instance.request({
        url: "/intelligent/data/wall/soilData",
        method: "GET"
    });
}