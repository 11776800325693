import { useState, useEffect } from "react";
import "./src/style.scss";
import { weatherInfo } from "../../api/api";
import { log } from "console";
import dayjs from "dayjs";
const ZHOU = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
];
const Header = () => {
  const [s, setS] = useState("");
  const [day, setDay] = useState("");
  const [zhou, setZhou] = useState("");
  let [weather, setweather]: any = useState();
  const _weatherInfo = async () => {
    try {
      let res = await weatherInfo();
      setweather((weather = res.data.forecasts[0].casts[0]));
    } catch (error) {}
  };
  const [year, setYear] = useState("");
  const [should, setShould] = useState("");
  useEffect(() => {
    setInterval(() => {
      const date = dayjs();
      const sound = date.format("HH:mm:ss");
      const day = date.format("YYYY-MM-DD");
      const time = date.day();
      setZhou(ZHOU[time]);
      setS(sound);
      setDay(day);
      // setZhou(zhou)
    }, 500);
    _weatherInfo();
  }, [should]);
  return (
    <header className="header">
      <div className="header__left">
        {/* 地址 */}
        <div className="header__left--site">
          <img src={require("../../static/image/cz.png")}></img>
        </div>
        {/* 天气 */}
        <div className="header__left--weather">
          <div className="header__left--weatherIcon"></div>
          <span>{weather ? weather.dayweather : ""}</span>
        </div>
        {/* 温度 */}
        <div className="header__left--temperature">
          <span>
            {weather
              ? `${weather.nighttemp_float}℃ ~ ${weather.daytemp_float}℃`
              : ""}
          </span>
        </div>
      </div>
      <div className="header__title">
        <span>九华礼智慧农业驾驶舱</span>
      </div>
      <div className="header__right">
        <span>{day}</span>
        <span>{zhou}</span>
        <span>{s}</span>
      </div>
    </header>
  );
};

export default Header;
