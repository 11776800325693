import Strip from "../../strip";
import Banner from "../../banner";
import Pressure from "../../pressure";
import { useEffect, useState } from "react";
import * as echarts from "echarts";
import "./src/style.scss";
import { getDeviceAllInfo, listHistoryData, soilData } from "../../../api/api";
import { formatTimeTwo } from "../../../utils/utils";
import "echarts-liquidfill";
/**
 *
 * @returns
 */
function Surface({
  id = "surface",
  data = "0",
  colorStops = [
    {
      offset: 1,
      color: "rgba(6, 187, 112, 0.3)", //下
    },
    {
      offset: 0,
      color: "rgba(11, 201, 199, 0.3)",
    },
  ],
  colorStopsx = [
    {
      offset: 1,
      color: "rgba(6, 187, 112, 1)", //下
    },
    {
      offset: 0,
      color: "rgba(11, 201, 199, 1)",
    },
  ],
}) {
  useEffect(() => {
    var chartDom = document.getElementById(id);
    var myChart = echarts.init(chartDom);
    var option = {
      // backgroundColor: "#000", //背景色
      title: {
        text: data + "℃",
        textStyle: {
          fontSize: 22,
          fontFamily: "Microsoft Yahei",
          fontWeight: "normal",
          color: "#fff",
        },
        x: "center",
        y: "50%",
      },
      series: [
        {
          type: "liquidFill", //配置echarts图类型
          radius: "95%",
          center: ["50%", "50%"],
          //  shape: 'roundRect',// 设置水球图类型（矩形[rect]，菱形[diamond]，三角形[triangle]，水滴状[pin],箭头[arrow]...） 默认为圆形
          data: [0.5, 0.5], //设置波浪的值
          //waveAnimation:false, //静止的波浪
          backgroundStyle: {
            borderWidth: 1,
            color: "transparent", //水球图内部背景色
          },
          outline: {
            borderDistance: 10,
            itemStyle: {
              borderWidth: 4,
              borderColor: colorStopsx[0].color,
            },
          },
          color: [
            //波浪颜色
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: colorStops,
              globalCoord: false,
            },
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: colorStopsx,
              globalCoord: false,
            },
          ],
          label: {
            normal: {
              formatter: "",
            },
          },
        },
      ],
    };
    option && myChart.setOption(option);
  }, [data]);
  return <div id={id} className="surfaceClass"></div>;
}
function Soil({
  soil = {
    temperature: 0,
    humidity: 0,
  },
}) {
  useEffect(() => {}, [soil]);
  return (
    <div className="soil">
      <div className="soil__box">
        <div className="soil__conten">
          <h2>{soil ? soil.temperature : 0}</h2>
          <p>温度，℃</p>
        </div>
        <div className="soil__conten">
          <h2>{soil ? soil.humidity : 0}</h2>
          <p>湿度，%</p>
        </div>
      </div>
    </div>
  );
}
function Monitor({ pmdata = [] }) {
  useEffect(() => {
    var chartDom = document.getElementById("monitor");
    var myChart = echarts.init(chartDom);
    var option = {
      legend: {
        icon: "none", //icon为圆角矩形
        x: "right",
        y: "0%",
        textStyle: {
          color: "#fff",
          fontSize: 14,
        },
      },
      grid: {
        x: "6%", //x 偏移量
        y: "9%", // y 偏移量
        width: "100%", // 宽度
        height: "80%", // 高度
      },
      axisLabel: {
        show: true,
        color: "#99B3C8",
        fontSize: 12,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: pmdata.map((value: any) => {
          return value.time;
        }),
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
      },
      yAxis: {
        type: "value",
        splitNumber: 1,
        splitLine: {
          show: true,
          lineStyle: {
            width: 1.5,
            color: ["rgba(153,179,200,0.5)"],
            type: [10, 8],
            dashOffset: 3,
          },
        },
      },
      series: [
        {
          name: "PM2.5",
          type: "line",
          symbol: "none",
          lineStyle: {
            color: "#F1FF9D",
          },
          data: pmdata.map((value: any) => {
            return value.temStr;
          }),
        },
        {
          name: "PM10",
          type: "line",
          symbol: "none",
          lineStyle: {
            color: "#FF7272",
          },
          data: pmdata.map((value: any) => {
            return value.humStr;
          }),
        },
      ],
    };
    option && myChart.setOption(option);
  }, [pmdata]);
  return <div id="monitor"></div>;
}
function Waterbox({ phdata = 7.3 }) {
  const rgcolor = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const num = 15;
  return (
    <div className="water">
      <div className="water__box">
        {rgcolor.map((item, index) => {
          return <p key={index}>{item}</p>;
        })}
      </div>
      <div className="water__back">
        <img src={require("../../../static/image/rgback.png")}></img>
      </div>
      <div
        className="water__zhizheng"
        style={{
          left: `${num * (phdata - 1) - 30}px`,
        }}
      >
        <p>PH值：{phdata}</p>
      </div>
    </div>
  );
}

function Environment(props: any) {
  const { soil } = props;
  useEffect(() => {
    console.log(soil);
  }, [soil]);
  return (
    <div className="environment">
      <div className="environment__box">
        <img src={require("../../../static/image/dl.gif")}></img>
        <div className="environment__conten">
          <div className="environment__circle">
            <img src={require("../../../static/image/circle.png")}></img>
          </div>
          <p>水源PH值</p>
        </div>
        <Waterbox phdata={soil ? parseFloat(soil.pH.toFixed(2)) : 7}></Waterbox>
      </div>
      <div className="environment__box">
        <img src={require("../../../static/image/dl.gif")}></img>
        <div className="environment__conten">
          <div className="environment__circle">
            <img src={require("../../../static/image/circle.png")}></img>
          </div>
          <p>土壤温湿度</p>
        </div>
        <Soil soil={soil}></Soil>
      </div>
    </div>
  );
}

function CulumnLeft() {
  let [pmdata, setPmdata] = useState();
  let [pressure, setPressure]: any = useState();
  let [soil, setsoil]: any = useState();
  const [num, setNum] = useState(1);
  const humidityData = [
    {
      offset: 1,
      color: "rgba(252, 148, 22, 0.3)", //下
    },
    {
      offset: 0,
      color: "rgba(252, 148, 22, 0.3)",
    },
  ];
  const humidityDatax = [
    {
      offset: 1,
      color: "rgba(252, 148, 22, 1)", //下
    },
    {
      offset: 0,
      color: "rgba(252, 148, 22, 1)",
    },
  ];
  const _listHistoryData = async () => {
    try {
      let res = await listHistoryData({ nodeId: 13 });
      res.data.forEach((item: any) => {
        item.time = formatTimeTwo(item.recordTime, "h:m");
      });
      setPmdata((pmdata = res.data));
      setTimeout(() => {
        setNum(num + 1);
      }, 500000);
    } catch (error) {}
  };
  const _soilData = async () => {
    try {
      let res: any = await soilData();
      console.log("_soilData", res.data);
      setsoil((soil = res.data));
    } catch (error) {}
  };
  const _getDeviceAllInfo = async () => {
    try {
      let res = await getDeviceAllInfo();
      setPressure((pressure = res.data.data[0].data));
    } catch (error) {}
  };
  useEffect(() => {
    _listHistoryData();
    _getDeviceAllInfo();
    _soilData();
  }, [num]);
  return (
    <div className="column">
      <Strip>
        <span>空气压力</span>
      </Strip>
      <Banner>
        <span>相对压力</span>
      </Banner>
      <Pressure plan={pressure ? pressure[5].humValueStr : "0"} />
      <Banner>
        <span>绝对压力</span>
      </Banner>
      <Pressure plan={pressure ? pressure[5].temValueStr : "0"} />
      <Strip>
        <span>空气湿度</span>
      </Strip>
      <div className="column__voluntarily">
        <img src={require("../../../static/image/dl.gif")}></img>
        <Surface id="surface" data={pressure ? pressure[2].humValueStr : "0"} />
      </div>
      <Strip>
        <span>温度</span>
      </Strip>
      <div className="column__voluntarily">
        <img src={require("../../../static/image/dl.gif")}></img>
        <Surface
          id="surfaced"
          data={pressure ? pressure[2].temValueStr : "0"}
          colorStops={humidityData}
          colorStopsx={humidityDatax}
        />
      </div>
      {/* <Strip><span>环境监测</span></Strip> */}
      {/* 检测 */}
      {/* 环境 */}
      <Environment soil={soil ? soil : ""} />
    </div>
  );
}

export default CulumnLeft;
